import * as React from "react"
import styled from 'styled-components/macro'
import DolomiteLogoWhite from '../../images/dolomite-logo-white.png'
import DolomiteLogoWhiteSVG from '../../images/dolomite-logo-white.svg'
import DolomiteLogoBlack from '../../images/dolomite-logo-black.png'
import DolomiteLogoBlackSVG from '../../images/dolomite-logo-black.svg'
import DolomiteSymbolWhite from '../../images/dolomite-symbol-white.png'
import DolomiteSymbolWhiteSVG from '../../images/dolomite-symbol-white.svg'
import DolomiteSymbolBlack from '../../images/dolomite-symbol-black.png'
import DolomiteSymbolBlackSVG from '../../images/dolomite-symbol-black.svg'
import { saveAs } from 'file-saver'

const LogosWrapper = styled.div`
  width: 100%;
  padding-top: 60px;
  background-color: #FFFFFF;
`

const LogosSection = styled.div`
  max-width: 1000px;
  margin: 0 auto;

  @media (max-width: 800px) {
    width: 90%;
  }
`

const LogosTitle = styled.h1`
  text-align: center;
  font-size: 30px;
  font-weight: 600;
`

const LogoDescription = styled.div`
  text-align: center;
  margin: 20px auto 40px;
  max-width: 530px;
`

const LogoWrapper = styled.div`
  width: calc(50% - 40px);
  height: 250px;
  text-align: center;
  margin: 20px;
  display: inline-block;

  @media (max-width: 800px) {
    width: calc(100% - 40px);
  }
`

const LogoBackground = styled.div`
  background-color: ${(props) => (props.dark ? '#1e1c29' : '#f9f9f9')};
  border-radius: 5px;
  height: 200px;

  img {
    margin: 65px 0;
    height: 70px;
  }
`

const LogoInfo = styled.div`
  margin-top: 10px;
`

const LogoTitle = styled.div`
  float: left;
  font-size: 18px;
  font-weight: 600;
  display: inline-block;
`

export const DownloadSection = styled.div`
  width: 100%;

  a {
    text-decoration: none;
  }
`

export const DownloadButton = styled.div`
  width: fit-content;
  padding: 12px 18px;
  border-radius: 5px;
  background: #565A69;
  color: white;
  cursor: pointer;
  margin: auto;
  font-weight: 400;

  :hover {
    background: #6C7284;
  }
`

const LogoButtons = styled.div`
  display: inline-block;
  float: right;
`

const ImgButton = styled.div`
  width: fit-content;
  padding: 4px 7px;
  border-radius: 5px;
  background: #6C7284;
  cursor: pointer;
  margin-left: 5px;
  font-weight: 400;
  display: inline-block;
  font-size: 12px;
  color: white;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;

  :hover {
    opacity: 0.8;
  }
`


const Logo = ({title, logo, svg}) => {
  const dolomiteTitle = `Dolomite ${title}`
  const downloadImage = (img) => {
    saveAs(img, dolomiteTitle.toLowerCase().split(' ').join('-'))
  }

  return (
    <LogoWrapper>
      <LogoBackground dark={!title.includes('Dark')}>
        <img src={logo} alt={dolomiteTitle} />
      </LogoBackground>
      <LogoInfo>
        <LogoTitle>{title}</LogoTitle>
        <LogoButtons>
          <ImgButton onClick={() => downloadImage(logo)}>PNG</ImgButton>
          <ImgButton onClick={() => downloadImage(svg)}>SVG</ImgButton>
        </LogoButtons>
      </LogoInfo>
    </LogoWrapper>
  )
}

const Logos = () => {
  return (
    <LogosWrapper>
      <LogosSection>
        <LogosTitle id='logos'>Logos</LogosTitle>
        <Logo title={'Logo White'} logo={DolomiteLogoWhite} svg={DolomiteLogoWhiteSVG}/>
        <Logo title={'Logo Dark'} logo={DolomiteLogoBlack} svg={DolomiteLogoBlackSVG}/>
        <Logo title={'Symbol White'} logo={DolomiteSymbolWhite} svg={DolomiteSymbolWhiteSVG}/>
        <Logo title={'Symbol Dark'} logo={DolomiteSymbolBlack} svg={DolomiteSymbolBlackSVG}/>
      </LogosSection>
    </LogosWrapper>
  )
}

export default Logos
