import * as React from "react"
import styled from 'styled-components/macro'
import { DownloadSection, DownloadButton } from './Logos'

const FontsWrapper = styled.div`
  width: 100%;
  padding-top: 120px;
  padding-bottom: 180px;
  background-color: #FFFFFF;
`

const FontsSection = styled.div`
  max-width: 600px;
  margin: 0 auto;
`

const FontsTitle = styled.h1`
  text-align: center;
  font-size: 30px;
  font-weight: 600;
`

const Font = styled.div`
  margin: 15px 0;
`

const FontExample = styled.div`
  background-color: #f9f9f9;
  width: calc(50% - 20px);
  display: inline-block;
  padding-top: 30px;
  padding-bottom: 30px;
  margin: 10px;
  border-radius: 5px;

  @media (max-width: 600px) {
    width: 80%;
    margin: 0 10%;
  }
`

const FontLine = styled.div`
  font-weight: ${(props) => props.weight};
  font-size: 20px;
  margin: 3px 20px;
`

const FontInfo = styled.div`
  width: calc(50% - 20px);
  margin: 50px 10px;
  display: inline-block;
  vertical-align: top;

  @media (max-width: 600px) {
    width: 80%;
    margin: 20px 10%;
  }
`

const FontTitle = styled.div`
  font-weight: 600;
  margin-bottom: 10px;
`

const Fonts = () => {
  return (
    <FontsWrapper>
      <FontsSection>
        <FontsTitle id='fonts'>
          Fonts
        </FontsTitle>
        <Font>
          <FontExample>
            <FontLine weight={700}>Open Sans Bold</FontLine>
            <FontLine weight={600}>Open Sans SemiBold</FontLine>
            <FontLine weight={500}>Open Sans Medium</FontLine>
            <FontLine weight={400}>Open Sans Regular</FontLine>
            <FontLine weight={300}>Open Sans Light</FontLine>
          </FontExample>
          <FontInfo>
            <FontTitle>Open Sans</FontTitle>
            <div>
              Open Sans is our primary font. Weight ranges from 300 - 700. Can be accessed below.
            </div>
          </FontInfo>
        </Font>
        <DownloadSection>
          <a href={'https://fonts.google.com/specimen/Open+Sans'} target={'_blank'}>
            <DownloadButton>Go to Font</DownloadButton>
          </a>
        </DownloadSection>
      </FontsSection>
    </FontsWrapper>
  )
}

export default Fonts
